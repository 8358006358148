import React from 'react';
import root from 'react-shadow/styled-components';
import debounce from 'lodash.debounce';
import {Header} from '@liveauctioneers/hammer-ui-proxibid/header';
import {HammerUIProvider} from '@liveauctioneers/hammer-ui-core/providers';
import {proxibidTheme} from '@liveauctioneers/hammer-ui-core/theme/proxibid';
import qs from 'qs';
import Cookies from 'universal-cookie';
import * as PropTypes from 'prop-types';
import HttpService from '../http/http-service';
import {useMegaNav} from './hooks';
import {
    AxiosProxy,
    EventIdentity,
    EventLogin,
    EventService,
    LoginFlowDialog,
    PersistedMessagesManager
} from '@proxibid/proxiponents';
import styled from 'styled-components';


const cookies = new Cookies();

const MegaNavHeader = (props) => {
    const {onLinkClicked, userState} = useMegaNav();
    const [inputValue, setInputValue] = React.useState('');
    const [suggestions, setSuggestions] = React.useState([]);
    const {identity} = useIdentity(props.clientProxy);

    const callAutoSuggestionAPI = (value) => {
        setInputValue(value);
    };

    const showHamburgerMenu = (isShown) => {
        document.body.classList.toggle('no-overflow', isShown);
    };

    const debounceAutoSuggestionAPI = React.useCallback(debounce(callAutoSuggestionAPI, 100), []);

    React.useEffect(() => {
        const fetchData = async () => {
            if (inputValue.length >= 3) {
                const result = await HttpService.get(`/catalog/catalog-browser/api/category/suggestions?lotSearchTerm=${inputValue}`, {});
                const formattedSuggestions = result.data.map((suggestion) => ({...suggestion, href: suggestion.path}));
                setSuggestions(formattedSuggestions);
            }
        };
        fetchData().then(r => r);
    }, [inputValue]);

    async function handleLoginSuccess() {
        try {
            await AxiosProxy.get('/asp/service/loginBidderByGuid.asp');
        } catch (e) {
            console.error(e);
        }
        location.reload();
    }

    return (
        <div>
            <root.div>
                <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;600&display=swap"
                      rel="stylesheet"/>
                <HammerUIProvider onLinkClick={onLinkClicked} theme={proxibidTheme} normalizationType={'scoped'}>
                    <Header
                        isSignedIn={userState.isLoggedIn}
                        onHeaderSearch={debounceAutoSuggestionAPI}
                        onHeaderSearchSubmitted={(value) => search(props.searchPageUrl, value)}
                        onSetBodyHidden={showHamburgerMenu}
                        onSignInClicked={() => sendToLoginInPage()}
                        searchSuggestions={suggestions}
                        userData={userState}

                    />
                </HammerUIProvider>
            </root.div>
            <PersistedMessagesManager
                identity={identity}
                profileUpdateLocation={props.profileUpdateLocation}
                sendEmail={props.sendEmail}
                verifyPhoneNumber={props.verifyPhoneNumber}
                contactSupportUrl={props.contactSupportUrl}
                privacyPolicyUrl={props.privacyPolicyUrl}
            />
            <HiddenDiv>
                <LoginFlowDialog
                    showDialog={false}
                    dialogTitle={'Buyer Sign In'}
                    captchaEnabled={props.captchaEnabled}
                    captchaApiKey={props.captchaApiKey}
                    loginUrl={`${props.clientProxy}/login/v1`}
                    onSuccess={handleLoginSuccess}
                    onClose={() => {
                    }}
                    unifiedUserAgreementUrl={'/docs/ProxibidUUA.pdf'}
                    privacyPolicyUrl={'/docs/ProxibidPrivacyPolicy.pdf'}
                    termsAndConditionsSubmitUrl={`${props.clientProxy}/terms-and-conditions/v1`}
                />
            </HiddenDiv>
        </div>
    );
};

const HiddenDiv = styled.div`
  display: none;
`;

MegaNavHeader.propTypes = {
    captchaApiKey: PropTypes.string.isRequired,
    captchaEnabled: PropTypes.bool.isRequired,
    clientProxy: PropTypes.string.isRequired,
    contactSupportUrl: PropTypes.string.isRequired,
    privacyPolicyUrl: PropTypes.string.isRequired,
    profileUpdateLocation: PropTypes.string.isRequired,
    searchPageUrl: PropTypes.string.isRequired,
    sendEmail: PropTypes.func.isRequired,
    verifyPhoneNumber: PropTypes.func.isRequired,
};

const useIdentity = (clientProxy) => {
    const [identity, setIdentity] = React.useState({
        emailVerificationCode: '',
        email: '',
        profileComplete: true
    });

    const fetchIdentity = async () => {
        try {
            const userId = cookies.get('userid') || 0;
            if (userId > 0) {
                const response = await AxiosProxy.get(
                    `${clientProxy}/identities/v1/${userId}`,
                    {params: {skip401Event: true}});
                EventService.dispatchEvent(EventLogin.EVENT_LOGIN_UPDATE_DISPLAY_NAME, response.data.displayName);
                EventService.dispatchEvent(EventIdentity.EVENT_IDENTITY_SHOW_BIDDER_STATUS, response.data.bidderRating);
                setIdentity(response.data);
            }
        } catch (error) {
            if (error.response.status === 401) {
                EventService.dispatchEvent(EventLogin.EVENT_LOGIN_UPDATE_DISPLAY_NAME);
            }
        }
    };

    React.useEffect(() => {
        fetchIdentity();
    }, []);

    return {identity};
};

const sendToLoginInPage = () => {
    const loginUrl = '/asp/LoginBidder.asp';
    window.location.href = `${loginUrl}?d=${window.location}`;
};

const search = (searchPageUrl, term, category) => {
    const cookieSearch = cookies.get('search');
    const cookieSort = cookieSearch ? cookieSearch.split('&')[0] : undefined;
    const cookieView = cookieSearch ? cookieSearch.split('&')[1] : undefined;
    const cookieLength = cookieSearch ? cookieSearch.split('&')[2] : undefined;

    const defaultCategory = 'all categories';
    const sort = qs.parse(window.location.hash.replace('#', '')).sort || cookieSort || 'relevance';
    const view = qs.parse(window.location.hash.replace('#', '')).view || cookieView || 'gallery';
    const length = qs.parse(window.location.hash.replace('#', '')).length || cookieLength || '25';

    if (!term.trim()) {
        return;
    }
    const searchTerm = term.replace(/&/g, '%26').replace(/ /g, '+').replace(/,/g, '%2C');

    let searchQuery = `${searchPageUrl}` +
        `?searchTerm=${searchTerm}` +
        `&category=${encodeURIComponent(defaultCategory)}` +
        `&timestamp=${Date.now()}` +
        `#searchid=0&type=lot&search=${searchTerm}&sort=${sort}&view=${view}&length=${length}`;

    if (category && category.toLowerCase() !== defaultCategory) {
        const refinedCategory = category.replace(/&/g, '%26').replace(/ /g, '+').replace(/,/g, '%2C');
        searchQuery = searchQuery + `&refine=Category|${refinedCategory}`;
    }

    searchQuery = searchQuery + '&start=1';

    window.location = searchQuery;
};


export default MegaNavHeader;
