import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';

export default class HttpService {

    static get(url, headers) {

        return axios.get(url, headers)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                this.handleError(url, error);
                return Promise.reject(error);
            });
    }

    static post(url, data, headers) {

        return axios.post(url, data, headers)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                this.handleError(url, error);
                return Promise.reject(error);
            });
    }

    static put(url, data, headers) {

        return axios.put(url, data, headers)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                this.handleError(url, error);
                return Promise.reject(error);
            });
    }

    static handleError(url, error) {
        console.error(`Failed on service request with url: ${url}. Error:  ${error}`);
    }

}