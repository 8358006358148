import {LogoutService, ProxiponentsConfig} from '@proxibid/proxiponents';

export default class ApplicationStartup {
    static startup(applicationConfig) {
        ApplicationStartup.setupProxiponentConfig(applicationConfig);

        LogoutService.instance.registerEventListeners();
    }

    static setupProxiponentConfig(applicationConfig) {
        const proxiponentsConfig = ProxiponentsConfig.instance;
        proxiponentsConfig.clientProxyUrl = applicationConfig.clientProxyUrl;
    }
}

