import React, {useEffect} from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import {EventIdentity, EventService, PersistedMessages} from '@proxibid/proxiponents';

PersistedMessagesManager.propTypes = {
    sendEmail: PropTypes.func.isRequired,
    emailValidationCode: PropTypes.string,
    profileUpdateLocation: PropTypes.string.isRequired,
    contactSupportUrl: PropTypes.string.isRequired,
    privacyPolicyUrl: PropTypes.string.isRequired,
    emailAddress: PropTypes.string,
    verifyPhoneNumber: PropTypes.func.isRequired,
    userRoutedFromUrl: PropTypes.string
};

function validationCodeNeverSent(emailValidationCode) {
    return emailValidationCode === '0000000000000000';
}

function expiredValidationCode(emailValidationCode) {
    const emailValidationCodeAsMoment = moment(emailValidationCode, 'YYYYMMDDHHmm');
    return moment().isAfter(emailValidationCodeAsMoment);
}

function PersistedMessagesManager(props) {
    useEffect(() => {
        if (props.emailValidationCode) {
            if (validationCodeNeverSent(props.emailValidationCode)) {
                EventService.dispatchEvent(EventIdentity.EVENT_IDENTITY_SHOW_EMAIL_VERIFICATION_NOT_SENT, props.emailAddress);
            } else if (expiredValidationCode(props.emailValidationCode)) {
                EventService.dispatchEvent(EventIdentity.EVENT_IDENTITY_SHOW_EMAIL_VERIFICATION_EXPIRED, props.emailAddress);
            } else {
                EventService.dispatchEvent(EventIdentity.EVENT_IDENTITY_SHOW_EMAIL_UNVERIFIED, props.emailAddress);
            }
        }

        if (props.userRoutedFromUrl) {
            EventService.dispatchEvent(EventIdentity.EVENT_IDENTITY_SHOW_USER_WAS_ROUTED, props.userRoutedFromUrl);
        }
    });

    return <PersistedMessages privacyPolicyUrl={props.privacyPolicyUrl} contactSupportUrl={props.contactSupportUrl}
                              sendEmail={props.sendEmail} profileUpdateLocation={props.profileUpdateLocation}
                              verifyPhoneNumber={props.verifyPhoneNumber}/>;
}

export default PersistedMessagesManager;
