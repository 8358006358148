import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import {theme, Header, Footer} from '@proxibid/proxiponents';
import MegaNavHeader from './js/mega-nav/mega-nav-header';
import MegaNavFooter from './js/mega-nav/mega-nav-footer';
import PersistedMessagesManager from './js/message/persisted-messages-manager';
import {ThemeProvider} from '@material-ui/styles';
import ApplicationStartup from './js/application-startup';

export default class BootstrapUtil {

    /* These are required on every page and thus are always added. This simplifies maintaining things that are needed on every page */
    static websiteFrameRequiredComponentMap = {
        Header,
        MegaNavHeader,
        MegaNavFooter,
        Footer,
        PersistedMessagesManager,
    };

    /* react component map example:
    {
    'PhoneVerification': PhoneVerification,
    'BidderApprovalQueue': BidderApprovalQueue,
    'MissingGeolocationReport': MissingGeolocationReport,
    }
    */
    static bootstrapEntrypoint (specificReactComponentMap) {
        let mergedComponentMap = {...this.websiteFrameRequiredComponentMap, ...specificReactComponentMap};
        this._onReady(() => {
            this._renderReactComponents(mergedComponentMap);
        });

        window.renderReactComponents = function () {
            BootstrapUtil._renderReactComponents(mergedComponentMap);
        };
    }

    static _onReady(callback) {
        if (document.readyState !== 'loading'){
            callback();
        } else {
            document.addEventListener('DOMContentLoaded', callback);
        }
    }


    static _renderReactComponents(specificReactComponentMap){
        ApplicationStartup.startup(window.applicationConfig);

        const allFoundReactComponentDivs = document.querySelectorAll('.react-component:not(.reactified)');
        for (let currentComponentDiv of allFoundReactComponentDivs) {
            try {
                const reactComponentLookup = currentComponentDiv.getAttribute('react-component');
                const SpecificReactComponent = specificReactComponentMap[reactComponentLookup];
                if (!SpecificReactComponent) {
                    console.error(`No react component mapping found for ${reactComponentLookup}`);
                    currentComponentDiv.innerHTML = `Yo 
dawg. You forgot to add "react-component" to your react-component which you should do if you want a React Component inside your react-component div. <br /><br /> <span style="font-size: 2em; font-weight: 900;">You need to add ${reactComponentLookup} to this pages entryPoint's reactComponentMap.</span>`;
                    return;
                }
                const specificBootstrap = eval(currentComponentDiv.getAttribute('bootstrap'));
                ReactDOM.render(<ThemeProvider theme={theme}><SpecificReactComponent {...specificBootstrap} /></ThemeProvider>, currentComponentDiv);
                currentComponentDiv.classList.add('reactified');
            } catch (error) {
                console.log('Failed to reactify: ', error, currentComponentDiv);
            }
        }

    }

}
