import {LogoutService} from '@proxibid/proxiponents';
import React from "react";
import Cookies from "universal-cookie";

const logoutService = LogoutService.instance;
const cookies = new Cookies();

const anonymousUser = {
    email: '',
    name: '',
    isLoggedIn: false
}

export const useMegaNav = () => {
    const [userState, setUserState] = React.useState(anonymousUser);
    const onLinkClicked = async (href) => {
        if (href === 'sign-out') {
            await logoutService.logout();
            setUserState(anonymousUser);
            const a2oAuthCookie = cookies.get('a2o_auth');
            if (a2oAuthCookie) {
                var returnURL = window.location.href;
                window.location.href = `/account/signout?returnURL=${returnURL}`;
            }
            else {   
                location.reload();
            }
        } else {
            window.location.href = href;
        }
    };

    React.useEffect(() => {
        const guid = cookies.get('guid');
        const encodedName = cookies.get('BidderDisplayName');
        if (guid && guid !== '' && encodedName && encodedName !== '') {
            setUserState({
                email: '',
                isLoggedIn: true,
                name: encodedName.replace(/\+/g, ' ')
            })
        }
    }, []);


    return {
        onLinkClicked,
        userState,
    }
}
