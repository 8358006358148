import React from 'react';
import root from 'react-shadow/styled-components';
import {Footer} from '@liveauctioneers/hammer-ui-proxibid/footer';
import {HammerUIProvider} from '@liveauctioneers/hammer-ui-core/providers';
import {proxibidTheme} from '@liveauctioneers/hammer-ui-core/theme/proxibid';
import {useMegaNav} from './hooks';

const MegaNavFooter = () => {
    const {onLinkClicked} = useMegaNav();
    const onSubscribeClick = async (email) => {
        fetch('/catalog/catalog-browser/api/newsletter/subscribe', {
            body: JSON.stringify({
                emailAddress: email,
            }),
            method: 'POST',
        });
        return await new Promise((resolve) => setTimeout(resolve, 2000));
    };

    return (
        <root.div>
            <link
                href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;600&display=swap"
                rel="stylesheet"/>
            <HammerUIProvider onLinkClick={onLinkClicked} theme={proxibidTheme} normalizationType={'scoped'}>
                <Footer onSubscribeClick={onSubscribeClick}/>
            </HammerUIProvider>
        </root.div>
    );
};

export default MegaNavFooter;
